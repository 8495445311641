<template>
  <div id="loginReady" v-loading.fullscreen="loading" element-loading-text="拼命加载中">
  </div>
</template>

<script type="text/ecmascript-6">
  export default {
    data() {
      return {
        loading: false,
        url_data: []
      };
    },
    created() {
      this.loading = true;
      // console.log(window.location.hash.split('?')[1].split('&'))
      let b = window.location.hash.split('?')[1].split('&')
      b.forEach(item => {
        this.url_data.push(item.split('=')[1])
      })
      localStorage.userToken = this.url_data[1]
      let path =''
      if(localStorage.jumpUrl){
         path = localStorage.jumpUrl
      }else{
          path = 'indexChildren'
      }
      this.$router.push({
        name: path,
        params: {}
      });
       localStorage.removeItem('jumpUrl')
    }
  };
</script>

